import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

import PageLayout from 'components/layout/PageLayout';
import { breakPoint } from 'shared/styles';
import SectionA from './SectionA';
import SectionB from './SectionB';
import SectionC from './SectionC';
import StibeeModal from './modal/StibeeModal';
import StockModal from './modal/StockModal';

const Main = () => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const [isStibeeModalOpen, setIsStibeeModalOpen] = useState(false);
  const [isStockModalOpen, setIsStockModalOpen] = useState(false);

  // TODO: 스티비 팝업
  // useEffect(() => {
  //   const expireDate = window.localStorage.getItem('ST_POP');
  //   const today = new Date();
  //   if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
  //     setIsStibeeModalOpen(true);
  //   }
  //   if (expireDate === 'Infinity') {
  //     return;
  //   }
  // }, []);

  //TODO: 신주발행 공고
  // useEffect(() => {
  //   const expireDate = window.localStorage.getItem('STOCK_POP');
  //   const today = new Date();
  //   if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
  //     setIsStockModalOpen(true);
  //   }
  //   if (expireDate === 'Infinity') {
  //     return;
  //   }
  // }, []);

  return (
    <PageLayout scrollSnap={!isMobile}>
      <SectionA />
      <SectionB />
      <SectionC />
      <StibeeModal
        isStibeeModalOpen={isStibeeModalOpen}
        setIsStibeeModalOpen={setIsStibeeModalOpen}
      />
      <StockModal
        isStockModalOpen={isStockModalOpen}
        setIsStockModalOpen={setIsStockModalOpen}
        isMobile={isMobile}
      />
    </PageLayout>
  );
};
export default Main;
